// import NotFound from "../components/NotFound";
// import Breeds from "../pages/Breeds";
// import BuyNow from "../pages/BuyNow";
// import Cavapoo101 from "../pages/Cavapoo101";
// import Contact from "../pages/Contact";
// import CustomerPhotos from "../pages/CustomerPhotos";
// import CustomerReviews from "../pages/CustomerReviews";
// import Faq from "../pages/Faq";
// import Home from "../pages/Home";
// import OrderConfirmedPage from "../pages/OrderConfirmedPage";
// import PetInsurance from "../pages/PetInsurance";
// import Privacy from "../pages/Privacy";
// import PupsDetails from "../pages/PupsDetails";
// import ReviewDetails from "../pages/ReviewDetails";
// import Shipping from "../pages/Shipping";
// import Teams from "../pages/Teams";
// import Testimonials from "../pages/Testimonials";
// import Warranty from "../pages/Warranty";
// import Tiptap from "../Tiptap";

import { lazy } from "react";
import NotFound from "../components/NotFound.jsx";

const Breeds = lazy(() => import("../pages/Breeds"));
const PupsDetails = lazy(() => import("../pages/PupsDetails"));
const Testimonials = lazy(() => import("../pages/Testimonials"));
const Faq = lazy(() => import("../pages/Faq"));
const Contact = lazy(() => import("../pages/Contact"));
const CustomerPhotos = lazy(() => import("../pages/CustomerPhotos"));
const ReviewDetails = lazy(() => import("../pages/ReviewDetails"));
const Home = lazy(() => import("../pages/Home"));
const CustomerReviews = lazy(() => import("../pages/CustomerReviews"));
const Shipping = lazy(() => import("../pages/Shipping"));
const Cavapoo101 = lazy(() => import("../pages/Cavapoo101.jsx"));
const BuyNow = lazy(() => import("../pages/BuyNow"));
const Tiptap = lazy(() => import("../Tiptap"));
const OrderConfirmedPage = lazy(() => import("../pages/OrderConfirmedPage.jsx"));
const Teams = lazy(() => import("../pages/Teams.jsx"));
const Warranty = lazy(() => import("../pages/Warranty.jsx"));
const PetInsurance = lazy(() => import("../pages/PetInsurance.jsx"));
const Privacy = lazy(() => import("../pages/Privacy.jsx"));
const Parent = lazy(()=> import("../pages/Parent.jsx"));

export const publicRoutes = [
  { path: "/", element: <Home /> },
  { path: "/puppies", element: <Breeds /> },
  { path: "/puppies/pups-details/:id", element: <PupsDetails /> },
  { path: "/puppies/:productName/buy-now/:id", element: <BuyNow /> },
  { path: "/testimonials", element: <Testimonials /> },
  { path: "/faq", element: <Faq /> },
  { path: "/contact", element: <Contact /> },
  { path: "/customer-photos/details/:id", element: <ReviewDetails /> },
  { path: "/customer-reviews", element: <CustomerReviews /> },
  { path: "/customer-photos", element: <CustomerPhotos /> },
  { path: "/cavapoo101", element: <Cavapoo101 /> },
  { path: "/test", element: <Tiptap /> },
  { path: "shipping", element: <Shipping /> },
  { path: "/confirm", element: <OrderConfirmedPage /> },
  { path: "/teams", element: <Teams /> },
  { path: "/health-guarantee", element: <Warranty /> },
  { path: "/pet-insurance", element: <PetInsurance /> },
  { path: "/privacy", element: <Privacy /> },
  { path: "/parent", element: <Parent /> },
  { path: "*", element: <NotFound /> },
];
