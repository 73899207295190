import React, { useState, useEffect } from "react";
import { AiFillEdit } from "react-icons/ai";

import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";

import GlobalLoader from "../../../components/GlobalLoader.jsx";
import { GlobalModalV1 } from "../../../components/GlobalModal.jsx";
import SpecialRequestForm from "./SpecialRequestForm.jsx";
import { getDate } from "../../../utils/index.js";
import {
  useAllSpecialRequest,
  useDeleteSpecialRequest,
} from "../../../features/Special-request/specialRequestServices.js";

import { LiaTimesSolid } from "react-icons/lia";
import { useDisclosure, useToast } from "@chakra-ui/react";
import EditBtn from "../../../components/EditBtn.jsx";
import DeleteBtn from "../../../components/DeleteBtn.jsx";

const Completed = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    data: requests = [],
    isLoading,
    error,
  } = useAllSpecialRequest("/api/special-requests");
  console.log("requests tabs===>", requests);

  const completedRequests = requests.filter(
    (request) => request.status === "complete",
  );

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const toast = useToast();

  const createDeleteMutation = useDeleteSpecialRequest();

  const handleDelete = () => {
    createDeleteMutation.mutate(
      { apiUrl: `/api/special-request/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);

          toast({
            title: "Special Request Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          console.log("Error deleted Special Request");
          toast({
            title: "Error deleted Special Request",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleUpdate = () => {
    const filter = requests?.filter((item) => item?._id === selectedIdEdit);
    console.log(filter, "filterfilterfilter");
    setSelectedData(filter);
    setSelectedIdEdit(null);
  };

  useEffect(() => {
    if (selectedId) {
      handleDelete();
      console.log(selectedId, "selectedId");
    }
    if (selectedIdEdit) {
      handleUpdate();
      console.log(selectedIdEdit, "selectedIdEdit");
    }
  }, [selectedId, selectedIdEdit]);

  const btnComponent = (props) => {
    const handle = () => {
      setSelectedIdEdit(props?.data?._id);
      onOpen();
    };
    return (
      <div className="flex gap-5 pt-2">
        <EditBtn onClick={handle} />
        <DeleteBtn onClick={() => setSelectedId(props.data._id)} />
      </div>
    );
  };

  const columns = [
    {
      headerName: "Date Requested",
      valueGetter: (params) => getDate(params?.data.createdAt),
    },
    {
      headerName: "Acquisition Date",
      valueGetter: (params) =>
        getDate(
          params?.data?.order?.puppies[0]?.puppyInformation?.dateAcquired,
        ),
    },
    {
      headerName: "Scheduled Date",
      valueGetter: (params) =>
        getDate(params?.data.requestInformation.scheduledDate),
    },
    {
      headerName: "Location",
      valueGetter: (params) => params?.data?.order?.destinationAddress?.address,
    },
    {
      headerName: "Name",
      valueGetter: (params) =>
        params?.data?.order?.destinationAddress?.firstName,
    },
    {
      headerName: "Chip",
      valueGetter: (params) =>
        params.data.order?.puppies[0].puppyInformation.idNumber,
    },
    {
      headerName: "Breed",
      valueGetter: (params) =>
        params.data.order?.puppies[0].puppyInformation.breed.breedInformation
          .breedName,
    },
    {
      headerName: "Date Of Birth",
      valueGetter: (params) =>
        getDate(params.data.order?.puppies[0].puppyInformation.dateOfBirth),
    },
    {
      headerName: "Request Information",
      field: "requestInformation.video",
    },
    {
      headerName: "Birth Certificate",
      field: "requestInformation.birthCertificate",
    },
    {
      headerName: "Sent",
      field: "requestInformation.sent",
    },
    {
      headerName: "Asap",
      field: "requestInformation.asap",
    },
    {
      headerName: "Photo",
      field: "requestInformation.photo",
    },
    {
      headerName: "Face Time",
      field: "requestInformation.faceTime",
    },
    {
      headerName: "Email",
      valueGetter: (params) => params?.data.customerPhotos.email,
    },
    {
      headerName: "Phone",
      valueGetter: (params) =>
        params?.data?.order?.contactInformation?.cellPhone,
    },
    {
      headerName: "Notes",
      valueGetter: (params) => params?.data.requestInformation.notes,
    },
    {
      headerName: "Last Name",
      valueGetter: (params) => params?.data?.customerPhotos?.lastName,
    },
    {
      headerName: "Pup Sold To",
      valueGetter: (params) =>
        params?.data?.order?.destinationAddress?.firstName,
    },
    {
      headerName: "Price",
      valueGetter: (params) => params?.data?.cost.price,
    },
    // {
    //   headerName: "Payment",
    //   valueGetter: (params) => params?.data?.cost?.payment,
    // },
    {
      headerName: "Remaining",
      field: "cost.chargeRemaining",
    },
    {
      headerName: "Date",
      valueGetter: (params) => getDate(params?.data?.createdAt),
    },
    {
      headerName: "Action",
      cellRenderer: btnComponent,
      floatingFilter: false,
    },
  ];

  const defaultColDef = {
    sortable: true,
    editable: true,
    filter: true,
    floatingFilter: true,
  };

  const items = [
    {
      label: "1st menu item",
      key: "1",
    },
    {
      label: "2nd menu item",
      key: "2",
    },
    {
      label: "3rd menu item",
      key: "3",
      danger: true,
    },
    {
      label: "4th menu item",
      key: "4",
      danger: true,
      disabled: true,
    },
  ];

  const menuProps = {
    items,
    onClick: (e) => console.log("Click", e),
  };

  if (isLoading) return <GlobalLoader />;
  if (error) return <p>Error loading products</p>;

  return (
    <div className="my-10">
      <div className="ag-theme-quartz" style={{ height: 500 }}>
        <AgGridReact
          modules={[ClientSideRowModelModule]}
          rowData={completedRequests}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[10, 25, 50]}
        />
      </div>

      <GlobalModalV1 isOpen={isOpen} onClose={onClose}>
        <SpecialRequestForm
          onClose={onClose}
          isUpdateModal={true}
          selectedData={selectedData}
        />
      </GlobalModalV1>
    </div>
  );
};

export default Completed;
