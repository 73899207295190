import React, { useState,useEffect } from "react";

const CustomTabs = ({
  tabs,
  tabClassName,
  activeTabClassName,
  tabPositionClassName,
}) => {
  const [activeTab, setActiveTab] = useState(tabs[0].label);


  return (
    <div className={`${tabPositionClassName}`}>
      <div className="">
        <div className="flex">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`rounded-full transition-colors duration-300 focus:outline-none ${index > 0 ? "-ml-4" : ""} ${activeTab === tab.label ? activeTabClassName : tabClassName}`}
              onClick={() => setActiveTab(tab.label)}
            >
              {tab.label}
            </button>
          ))}
        </div>
      </div>
      <div className="mt-8">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`transition-opacity duration-300 ${activeTab === tab.label ? "opacity-100" : "opacity-0"} ${activeTab === tab.label ? "block" : "hidden"}`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomTabs;
