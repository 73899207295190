import React, { useEffect, useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { AiFillEdit } from "react-icons/ai";
import { LiaTimesSolid } from "react-icons/lia";
import AddPage from "./components/AddPage";
import { useAllPages, useDeletePage } from "../../features/Page/pageServices";
import { useToast } from "@chakra-ui/react";
import { Modal } from "antd";
import EditBtn from "../../components/EditBtn";
import DeleteBtn from "../../components/DeleteBtn";

const Pages = () => {
  const [showPage, setShowPage] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const toast = useToast();
  const { data: pages, isLoading, error } = useAllPages("/api/pages");
  const deleteMutation = useDeletePage();
  console.log("pages====>", pages);

  useEffect(() => {
    if (selectedId) {
      handleDelete();
      console.log(selectedId, "selectedId");
    }
    if (selectedIdEdit) {
      handleUpdate();
      console.log(selectedIdEdit, "selectedIdEdit");
    }
  }, [selectedId, selectedIdEdit]);

  // Handle updates and setting selected data
  const handleUpdate = () => {
    console.log("selectedIdEdit===>", selectedIdEdit);

    const filter = pages?.filter((item) => item?._id === selectedIdEdit);
    console.log(filter, "filter");

    setSelectedData(filter); // This updates the state, but it's asynchronous
    setShowPage(true); // Trigger to show the AddPage
    setSelectedIdEdit(null); // Reset the ID after update
  };

  const handleDelete = () => {
    deleteMutation.mutate(
      { apiUrl: `/api/page/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);

          toast({
            title: "Page Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          console.log("Error deleted Page");
          toast({
            title: "Error deleted page",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const pageHandler = () => {
    setShowPage(!showPage);
  };

  //Table data button component
  const btnComponent = (props) => {
    const handle = () => {
      setSelectedIdEdit(props?.data?._id);
    };
    return (
      <div className="flex gap-5 pt-2">
        <EditBtn onClick={handle} />
        <DeleteBtn onClick={() => handleDeleteClick(props.data._id)} />
      </div>
    );
  };

  const handleDeleteClick = (id) => {
    setItemToDelete(id);
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = () => {
    setSelectedId(itemToDelete);
    setShowDeleteConfirm(false);
    setItemToDelete(null);
  };

  const columns = [
    { headerName: "ID(name)", field: "id" },
    { headerName: "Title", field: "title" },
    {
      headerName: "Action",
      cellRenderer: btnComponent,
      floatingFilter: false,
    },
  ];

  const transformPage =
    pages?.map((page, index) => ({
      _id: page?._id ?? "",
      id: page?.slug ?? "",
      heading: page?.heading ?? "",
      title: page?.pageTitle ?? "",
      metaDescription: page?.metaDescription ?? "",
      content: page?.content ?? "",
    })) ?? [];
  const defaultColDef = {
    sortable: true,
    editable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
  };
  return (
    <div className="my-5">
      {showPage ? (
        // Render AddPage when showPage is true
        <AddPage
          pageHandler={pageHandler}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      ) : (
        // Render the button and AgGrid when showPage is false
        <div>
          <div className="my-5 flex flex-1 flex-col">
            <button
              onClick={pageHandler}
              className="self-start rounded-lg border-2 border-black bg-transparent px-4 py-2 hover:bg-black hover:text-white"
            >
              Add New Page
            </button>
          </div>
          <div className="ag-theme-quartz" style={{ height: 500 }}>
            <AgGridReact
              modules={[ClientSideRowModelModule]}
              rowData={transformPage}
              columnDefs={columns}
              defaultColDef={defaultColDef}
              rowSelection="multiple"
              pagination={true}
              paginationPageSize={50}
              paginationPageSizeSelector={[10, 25, 50]}
            />
          </div>
        </div>
      )}
      <Modal
        title="Confirm Delete"
        open={showDeleteConfirm}
        onOk={handleConfirmDelete}
        onCancel={() => setShowDeleteConfirm(false)}
        okText="Yes, delete"
        cancelText="Cancel"
        centered
      >
        <p>Are you sure you want to delete this page?</p>
      </Modal>
    </div>
  );
};

export default Pages;
