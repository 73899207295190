import React from "react";

const BreederFooterInfo = () => {
  return (
    <div className="my-16 grid w-full grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
      <div className="px-4 py-10 text-center text-primaryBlack-theme sm:px-10">
        <p className="text-2xl font-medium uppercase tracking-wide text-primaryBlack-theme/50">
          Puppies with a
        </p>
        <h6 className="text-5xl">guarantee</h6>
        <p className="py-8 font-medium leading-7">
          The health of your new family member is our primary concern. We take
          all necessary precautions from vet inspection of our pups to their
          parents requirement to pass annual exams. If there&apos;s something
          undiscoverable at 8 weeks of age that later comes up as hereditary or
          congenital, you&apos;re covered. We stand behind our pups with up to a
          10 Year guanrantee.
        </p>
      </div>
      <div className="px-4 py-10 text-center text-primaryBlack-theme sm:px-10">
        <p className="text-2xl font-medium uppercase tracking-wide text-primaryBlack-theme/50">
          Breeders with
        </p>
        <h6 className="text-5xl">Integrity</h6>
        <p className="py-8 font-medium leading-7">
          Breeders with Integrity" emphasizes ethical practices, transparency,
          and a commitment to animal welfare among breeders. These breeders
          prioritize the health, socialization, and well-being of their animals,
          ensuring responsible breeding practices, maintaining high standards of
          care, and following ethical guidelines. Their goal is to provide
          happy, healthy animals and build trust with potential pet owners
          through open communication and integrity.
        </p>
      </div>
      <div className="px-4 py-10 text-center text-primaryBlack-theme sm:px-10">
        <p className="text-2xl font-medium uppercase tracking-wide text-primaryBlack-theme/50">
          Breeders with
        </p>
        <h6 className="text-5xl">Commitment</h6>
        <p className="py-8 font-medium leading-7">
          Breeders with Commitment" highlights breeders dedicated to the
          lifelong health and happiness of their animals. These breeders invest
          time and resources into responsible breeding, high-quality care, and
          ongoing support for pet owners. They are committed to producing
          well-socialized, healthy animals and maintaining a strong relationship
          with new owners, offering guidance and assistance as the pets grow.
          Their dedication reflects a passion for the breed and a deep
          responsibility toward both the animals and future owners.
        </p>
      </div>
    </div>
  );
};

export default BreederFooterInfo;
