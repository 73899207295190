import React, { useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
} from "@chakra-ui/react";
import { GlobalModalV1 } from "../../components/GlobalModal";
import ParentTable from "./components/Parent/ParentTable";
import ParentForm from "./components/Parent/ParentForm";


const ParentInfo = () => {
  const [isShowForm, setIsShowForm] = useState(false);

  return (
    <div className="my-10">
      <div className="flex flex-1 flex-col">
        <Tabs>
          <TabList>
            <Tab>Add Parent</Tab>
          
          </TabList>

          <TabPanels>
            <TabPanel>
              <Parent />
            </TabPanel>
          
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default ParentInfo;

const Parent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <button
        onClick={onOpen}
        className="my-4 self-start rounded-lg border-2 border-black bg-transparent px-4 py-2"
      >
        Add Parent
      </button>

      <GlobalModalV1 isOpen={isOpen} onClose={onClose}>
        <ParentForm onClose={onClose} isOpen={isOpen} />
      </GlobalModalV1>

      <ParentTable />
    </>
  );
};

