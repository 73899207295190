import { message } from "antd";
import moment from "moment";

export function getPuppyAgeInWeeks(dob) {
  const birthDate = new Date(dob);
  const currentDate = new Date();
  const differenceInTime = currentDate.getTime() - birthDate.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  const ageInWeeks = Math.floor(differenceInDays / 7);
  return ageInWeeks;
}
export function convertAge(dateStr) {
  const date = new Date(dateStr);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
}

// export function getDate(date) {
//   const formattedDate = new Date(date).toISOString().split('T')[0];
//   return formattedDate;
// }

export function getDate(date) {
  const formattedDate = moment(date).format("MM/DD/YYYY hh:mm A");
  return formattedDate;
}

// export function getDate(dates) {
//   // Handle both single date and array of dates
//   if (!dates) return ""; // Return empty if dates are undefined
//   const dateArray = Array.isArray(dates) ? dates : [dates];

//   // Format each date individually
//   return dateArray.map((date) =>
//     date ? moment(date).format("MMM Do YY") : "",
//   );
// }

// export const getLocationByZip = async (zipcode) => {
//   try {
//     const response = await fetch(`https://api.zippopotam.us/us/${zipcode}`);
//     if (response.ok) {
//       const data = await response.json();
//       const location = data.places?.[0] || null; // Ensure location is not null
//       return {
//         zipcode,
//         location,
//         country: data.country,
//         error: "",
//       };
//     } else {
//       console.error("Invalid ZIP code or API request failed.");
//       return {
//         error: "Invalid ZIP code",
//         location: null,
//       };
//     }
//   } catch (error) {
//     console.error("Error fetching location data:", error);
//     return {
//       error: "Error fetching location data",
//       location: null,
//     };
//   }
// };

export const getLocationByZip = async (zipcode) => {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode}&key=${process.env.REACT_APP_GOOGLE_CLOUD_API_KEY}`,
  );
  const data = await response.json();

  if (data.status === "OK") {
    const results = data.results[0];

    console.log("Location Result==>", results);

    const city = results.address_components.find((component) =>
      component.types.includes("locality"),
    )?.long_name;
    const postalCode = results.address_components.find((component) =>
      component.types.includes("postal_code"),
    )?.long_name;
    const state = results.address_components.find((component) =>
      component.types.includes("administrative_area_level_1"),
    )?.long_name;
    const stateAbbreviation = results.address_components.find((component) =>
      component.types.includes("administrative_area_level_1"),
    )?.short_name;

    const country = results.address_components.find((component) =>
      component.types.includes("country"),
    );

    const location = results.geometry.location;

    return { city, country, postalCode, stateAbbreviation, state, location };
  } else {
    console.error("Geocoding failed:", data.status);
  }
};

//Delete Images Handler
export const deleteImages = (file, setFileList) => {
  if (typeof setFileList === 'function') {
    setFileList((prev) => prev.filter((f) => f.uid !== file.uid));
  } else {
    console.error('Provided setter is not a function');
  }
};

//Image Preview Handler

export const handleBeforeUpload = (file, setFileList, supportedFormats = ["image/png", "image/jpeg", "image/jpg", "image/webp"]) => {
  const isSupportedFormat = supportedFormats.includes(file.type);

  if (!isSupportedFormat) {
    message.error(`You can only upload ${supportedFormats.join(", ")} files!`);
    return false;
  }

  const newFile = {
    ...file,
    uid: file.uid,
    name: file.name,
    status: "done",
    originFileObj: file,
  };

  setFileList((prev) => [...prev, newFile]);
  return false;
};

