import React, { useMemo } from "react";
import { useAllPages } from "../features/Page/pageServices";
import DOMPurify from "dompurify";
import BreederFooterInfo from "../components/BreederFooterInfo";

const Privacy = () => {
  const { data: page, error, isLoading } = useAllPages("/api/pages");

  const pageData = page?.find((shipping) => shipping?.slug === "privacy");
  console.log("Privacy Page Data===>", pageData);

  const sanitizedContent = useMemo(
    () => ({
      __html: DOMPurify.sanitize(pageData?.content),
    }),
    [pageData?.content],
  );
  return (
    <div className="poppins-regular relative w-full">
      <div
        className="absolute left-0 top-0 h-[650px] w-full bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: "url('/images/top-banner-contact.png')" }}
      ></div>
      <div className="relative z-10 flex w-full flex-col items-center justify-center px-4 pt-56 md:px-32">
        <h6 className="text-3xl md:text-5xl">Privacy Policy</h6>

        <div
          className="my-20 flex w-full max-w-5xl flex-col"
          dangerouslySetInnerHTML={sanitizedContent}
        />
        <BreederFooterInfo />
      </div>
    </div>
  );
};

export default Privacy;
