import React, { useEffect, useMemo, useState } from "react";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { GlobalModalV1 } from "../../../components/GlobalModal";
import PupsForm from "./PupsForm";
import PupsTable from "./PupsTable";
import { availablePups_tableData } from "../../../utils/data";
import {
  useAllPups,
  useDeletePupsMutation,
  useUpdatePupsMutation,
} from "../../../features/pups/pupsService";
import { getDate } from "../../../utils";
import { RxOpenInNewWindow } from "react-icons/rx";
import { Modal } from "antd";

const Available = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const updateMutation = useUpdatePupsMutation();
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [filters, setFilters] = useState({
    available: "",
    details: "",
    views: "",
    checkboxes: "",
    desc: "",
    wholesale: "",
  });
  const toast = useToast();

  const extendedClose = () => {
    onClose();
    setSelectedIdEdit(null);
    setSelectedData([]);
  };
  const { data: puppies, isLoading, error } = useAllPups("/api/puppies");
  console.log("puppies===>", puppies);

  const createDeleteMutation = useDeletePupsMutation();

  const handle = (id) => {
    setSelectedIdEdit(id);
    onOpen();
  };

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleDeleteClick = (id) => {
    setItemToDelete(id);
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = () => {
    setSelectedId(itemToDelete);
    setShowDeleteConfirm(false);
    setItemToDelete(null);
  };
  
  const handleDelete = (id) => {
    createDeleteMutation.mutate(
      { apiUrl: `/api/puppy/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);

          toast({
            title: "Puppy Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          console.log("Error deleted Puppy");
          toast({
            title: "Error deleted Puppy",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleUpdate = () => {
    const filter = puppies?.filter((item) => item?._id === selectedIdEdit);
    console.log(filter, "filterfilterfilter");
    setSelectedData(filter);
    setSelectedIdEdit(null);
  };

  useEffect(() => {
    if (selectedIdEdit) {
      handleUpdate();
      console.log(selectedIdEdit, "selectedIdEdit");
    }
    if(selectedId) {
      handleDelete();
      console.log('selectedId', selectedId);
    }
  }, [selectedIdEdit,selectedId]);

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };
  const filteredData = availablePups_tableData.filter(
    (item) =>
      item.available.includes(filters.available) &&
      item.details.toLowerCase().includes(filters.details.toLowerCase()) &&
      item.views.toLowerCase().includes(filters.views.toLowerCase()) &&
      item.checkboxes
        .toLowerCase()
        .includes(filters.checkboxes.toLowerCase()) &&
      item.desc.toLowerCase().includes(filters.desc.toLowerCase()) &&
      item.wholesale.toLowerCase().includes(filters.wholesale.toLowerCase()),
  );

  console.log(filteredData, "filteredData");

  const handleViewListing = (listingId) => {
    window.open(`/puppies/pups-details/${listingId}`, "_blank");
  };
  return (
    <div className="my-10">
      <div className="flex flex-1 flex-col">
        <button
          onClick={onOpen}
          className="self-start rounded-lg border-2 border-black bg-transparent px-4 py-2 hover:bg-black hover:text-white"
        >
          Add Pups
        </button>

        <GlobalModalV1 onClose={extendedClose} isOpen={isOpen}>
          <PupsForm onClose={extendedClose} isOpen={isOpen} />
        </GlobalModalV1>
      </div>
      <div className="my-10">
        <div className="rounded-md border border-gray-200">
          <div className="grid grid-cols-12 gap-4 p-4">
            <div className="col-span-5 grid grid-cols-2 gap-4">
              <div className="col-span-1 font-bold">Image</div>
              <div className="col-span-1 font-bold">
                <input
                  type="text"
                  placeholder="Details"
                  name="details"
                  value={filters.details}
                  onChange={handleFilterChange}
                  className="w-full rounded border border-gray-300 bg-transparent p-2 font-medium outline-none"
                />
              </div>
            </div>
            <div className="col-span-1 font-bold">
              <input
                type="text"
                placeholder="Views"
                name="views"
                value={filters.views}
                onChange={handleFilterChange}
                className="w-full rounded border border-gray-300 bg-transparent p-2 font-medium outline-none"
              />
            </div>
            <div className="col-span-1 font-bold">
              <input
                type="text"
                placeholder="Checkboxes"
                name="checkboxes"
                value={filters.checkboxes}
                onChange={handleFilterChange}
                className="w-full rounded border border-gray-300 bg-transparent p-2 font-medium outline-none"
              />
            </div>
            <div className="col-span-3 font-bold">
              <input
                type="text"
                placeholder="Description"
                name="desc"
                value={filters.desc}
                onChange={handleFilterChange}
                className="w-full rounded border border-gray-300 bg-transparent p-2 font-medium outline-none"
              />
            </div>
            <div className="col-span-2 font-bold">
              <input
                type="text"
                placeholder="Wholesale"
                name="wholesale"
                value={filters.wholesale}
                onChange={handleFilterChange}
                className="w-full rounded border border-gray-300 bg-transparent p-2 font-medium outline-none"
              />
            </div>
          </div>

          {puppies &&
            puppies?.map((item, index) => (
              <div
                className="grid grid-cols-12 gap-4 border-t border-gray-200 p-4"
                key={index}
              >
                <div className="col-span-5 grid grid-cols-2 items-center justify-center gap-4">
                  <div className="h-[200px] w-[200px] bg-center">
                    <img
                      src={
                        item?.listingInformation?.photos?.[0] ||
                        "default-image-url"
                      }
                      alt="breed-img"
                      className="h-full w-full rounded-2xl bg-center object-cover"
                    />
                  </div>
                  <div>
                    <p>
                      Available
                      <p>{getDate(item.createdAt)}</p>
                    </p>
                    <p>
                      {item.listingInformation.name}
                      <span className="m-1 text-xl font-bold">•</span>
                      {item.puppyInformation.cageNumber}
                    </p>
                    <p>
                      {item.listingInformation.heading}
                      <span className="ml-2 text-xl font-bold">•</span>
                      {item.puppyInformation.idNumber}
                    </p>
                    <p>
                      {item.puppyInformation?.breed?.breedInformation.breedName}
                    </p>
                    <p>
                      {item.puppyInformation.gender}
                      <span className="m-1 text-xl font-bold">•</span>
                      {item.puppyInformation.registration}
                      <span className="m-1 text-xl font-bold">•</span>
                      {item.puppyInformation.weight} lb
                    </p>
                    <p>
                      {getDate(item.puppyInformation.dateAcquired)}
                      <span className="m-1 text-xl font-bold">•</span>
                      {item.listingInformation.title}
                      <span className="m-1 text-xl font-bold">•</span>
                      {item.listingInformation.adultWeight}
                    </p>
                    <p>{item.puppyInformation.cost}</p>

                    <br />

                    <div className="mt-2 flex space-x-2">
                      <button
                        onClick={() => handle(item._id)}
                        className="rounded bg-blue-500 px-2 py-1 text-sm text-white"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleViewListing(item._id)}
                        className="rounded bg-green-500 px-2 py-1 text-sm text-white flex justify-center items-center gap-4"
                      >
                        View 
                        <RxOpenInNewWindow />
                      </button>
                      {/* <button className="rounded bg-yellow-500 px-2 py-1 text-sm text-white">
                        Special Request
                      </button> */}
                      <button
                        onClick={() => handleDeleteClick(item._id)}
                        className="rounded bg-red-500 px-2 py-1 text-sm text-white"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-span-1">
                  <p>{item.listingInformation.offerPrice}</p>
                </div>
                <div className="col-span-1">
                  {/* <div className="flex items-center">
                    <span>Feature:</span>
                    <input
                      type="checkbox"
                      checked={item.listingInformation.feature}
                      readOnly
                      className="ml-2"
                    />
                  </div>
                  <div className="mt-2 flex items-center">
                    <span>DNL:</span>
                    <input
                      type="checkbox"
                      checked={item.listingInformation.doNotList}
                      readOnly
                      className="ml-2"
                    />
                  </div> */}
                </div>

                <div className="col-span-3">
                  {item.listingInformation.description}
                </div>
                <div className="col-span-2">
                  {item.listingInformation.price && (
                    <p>{item.listingInformation.price}</p>
                  )}
                  {item.puppyInformation.purchaser && (
                    <p>{item.puppyInformation.purchaser}</p>
                  )}
                  {item.listingInformation.quantity && (
                    <p>{item.listingInformation.quantity}</p>
                  )}
                  {item.puppyInformation.wholesaleTransferDate && (
                    <p>
                      {getDate(item.puppyInformation.wholesaleTransferDate)}
                    </p>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* <PupsTable /> */}
      <GlobalModalV1 isOpen={isOpen} onClose={extendedClose}>
        <PupsForm
          onClose={extendedClose}
          isUpdateModal={true}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      </GlobalModalV1>
      
      <Modal
        title="Confirm Delete"
        open={showDeleteConfirm}
        onOk={handleConfirmDelete}
        onCancel={() => setShowDeleteConfirm(false)}
        okText="Yes, delete"
        cancelText="Cancel"
        centered
      >
        <p>Are you sure you want to delete this insurance record?</p>
      </Modal>
    </div>
  );
};

export default Available;
