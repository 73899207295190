import React, { useEffect, useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";

import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { AiFillEdit } from "react-icons/ai";
import { LiaTimesSolid } from "react-icons/lia";
import {
  useCreateDeliveryOption,
  useAllDeliveryOptions,
  useDeleteDeliveryOptionMutation,
} from "../../../features/delivery-option/deliveryOptionsService";

import { useDisclosure, useToast } from "@chakra-ui/react";
import toast from "react-hot-toast";
import { GlobalModalV1 } from "../../../components/GlobalModal";
import AddLocationForm from "./AddLocationForm";
import moment from "moment";
import AntModal from "../../../components/AntModal";
import EditBtn from "../../../components/EditBtn";
import DeleteBtn from "../../../components/DeleteBtn";

const TableLocation = () => {
  const {
    data: deliveryOption,
    isLoading,
    error,
  } = useAllDeliveryOptions("/api/deliveriesOptions");
  const deleteMutation = useDeleteDeliveryOptionMutation();
  console.log("Deliveries====>>>", deliveryOption);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [actionType, setActionType] = useState(null);
  const toast = useToast();

  // const filterProduct = deliveryOption?.map((product, index) => ({
  //   ...product,
  //   id: index + 1,
  //   // createdAt: moment(product.createdAt).format("DD MM YY"),
  // }));

  const handleDelete = () => {
    setActionType("delete");
    setIsModalVisible(true);
  };

  const handleUpdate = () => {
    const filter = deliveryOption?.filter(
      (item) => item?._id === selectedIdEdit,
    );
    setSelectedData(filter);
    setSelectedIdEdit(null);
    onOpen();
  };

  useEffect(() => {
    if (selectedId) {
      handleDelete();
    }
    if (selectedIdEdit) {
      handleUpdate();
    }
  }, [selectedId, selectedIdEdit]);

  const handleConfirm = async () => {
    setIsModalVisible(false);
    deleteMutation.mutate(
      { apiUrl: `/api/deliveriesOption/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);
          toast({
            title: "Deliveries Option Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          console.log("Error deleted deliveriesOption");
          toast({
            title: "Error deleted deliveriesOption",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setActionType(null);
    setSelectedId(null);
  };

  const btnComponent = (props) => {
    const handleEdit = () => {
      setSelectedIdEdit(props?.data?._id);
    };

    const handleDeleteClick = () => {
      setSelectedId(props?.data?._id);
    };

    return (
      <div className="flex gap-5 pt-2">
        <EditBtn onClick={handleEdit} />
        <DeleteBtn onClick={handleDeleteClick} />
      </div>
    );
  };

  const tableColumnData = [
    {
      headerName: "Name",
      field: "name",
    },
    {
      headerName: "Airport Code",
      field: "airportCode",
    },
    {
      headerName: "Place",
      field: "place",
    },
    {
      headerName: "Address",
      field: "address",
    },
    {
      headerName: "State",
      field: "state",
    },
    {
      headerName: "Active",
      field: "active",
    },
    {
      headerName: "No Sales Tax",
      field: "noSalesTax",
    },
    {
      headerName: "Tax Rate",
      field: "taxRate",
    },
    {
      headerName: "Tax Delivery",
      field: "taxDelivery",
    },
    {
      headerName: "Pricing",
      field: "pricing",
    },
    {
      headerName: "Restricted Breeds",
      field: "restrictedBreeds",
    },
    {
      headerName: "DeliveryMethod",
      field: "deliveryMethod",
    },
    {
      headerName: "Zone",
      field: "zone",
    },
    {
      headerName: "Actions",
      cellRenderer: btnComponent,
      floatingFilter: false,
    },
  ];

  const tableRowData =
    deliveryOption?.map((deliveryOption, index) => ({
      _id: deliveryOption?._id ?? "",
      name: deliveryOption?.name ?? "",
      airportCode: deliveryOption?.airportCode ?? "",
      place: deliveryOption?.place ?? "",
      address: deliveryOption?.address ?? "",
      state: deliveryOption?.state ?? "",
      active: deliveryOption?.active ?? "",
      noSalesTax: deliveryOption?.noSalesTax ?? "",
      taxRate: deliveryOption?.taxRate ?? "",
      taxDelivery: deliveryOption?.chargeTaxOnDeliveryService ?? "",
      pricing: deliveryOption?.pickUp ?? "",
      restrictedBreeds: deliveryOption?.restrictedBreeds ?? "",
      deliveryMethod: deliveryOption?.deliveryMethod ?? "",
      zone: deliveryOption?.zone ?? "",
    })) ?? [];

  const defaultColDef = {
    sortable: true,
    editable: true,
    filter: false,
    floatingFilter: true,
  };

  return (
    <div className="ag-theme-quartz my-5" style={{ height: 500 }}>
      <AgGridReact
        debug={true}
        modules={[ClientSideRowModelModule]}
        rowData={tableRowData}
        columnDefs={tableColumnData}
        defaultColDef={defaultColDef}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        pagination={true}
        paginationPageSize={10}
        paginationPageSizeSelector={[10, 25, 50]}
      />

      <GlobalModalV1 isOpen={isOpen} onClose={onClose}>
        <AddLocationForm
          onClose={onClose}
          isUpdateModal={true}
          selectedData={selectedData}
        />
      </GlobalModalV1>

      <AntModal
        centered
        isOpen={isModalVisible}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title={
          actionType === "approve" ? "Confirm Approval" : "Confirm Deletion"
        }
        content={
          actionType === "approve"
            ? "Are you sure you want to approve this delivery option?"
            : "Are you sure you want to delete this delivery option?"
        }
        okText={"Confirm"}
      />
    </div>
  );
};

export default TableLocation;
