import React, { useState, useEffect } from "react";
import { Button, DatePicker, Dropdown, Space } from "antd";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { LiaTimesSolid } from "react-icons/lia";
import { MdCheck, MdKeyboardArrowDown } from "react-icons/md";
import GlobalLoader from "../../../components/GlobalLoader.jsx";
import { AiFillEdit } from "react-icons/ai";
import { GlobalModalV1 } from "../../../components/GlobalModal.jsx";
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";

import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useAllBooking,
  useDeleteBooking,
} from "../../../features/Bookings/BookingService.js";
import BookingForms from "../BookingForms.jsx";
import AntModal from "../../../components/AntModal.jsx";
import { getDate } from "../../../utils/index.js";
import EditBtn from "../../../components/EditBtn.jsx";
import DeleteBtn from "../../../components/DeleteBtn.jsx";

const Puppies = () => {
  const { data: bookings, isLoading, error } = useAllBooking("/api/bookings");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isShowDetail, setIsShowDetail] = useState({});
  const [isToggled, setIsToggled] = useState(false);
  const navigate = useNavigate();
  // const updateMutation = useUpdateOrdersMutation();

  console.log("bookings===>", bookings);

  const transformBooking =
    bookings?.map((booking, index) => ({
      _id: booking?._id,
      id: index + 1,
      breeder: booking?.breeder ?? "",
      usdaName: booking?.usdaName ?? "",
      breed: booking?.breed ?? "",
      dateOfBirth: moment(booking?.dateOfBirth).format("DD-MM-YY") ?? "",
      usdaId: booking?.usdaId ?? 0,
      gender: booking?.gender ?? 0,
      color: booking?.color ?? 0,
      type: booking?.type ?? 0,
      registration: booking?.registration ?? "",
      dam: booking?.dam ?? "",
      sire: booking?.sire ?? "",
      sProgram: booking?.sProgram ?? "",
      dispositionDate: booking?.dispositionDate ?? "",
      psWeek: booking?.psWeek ?? "",
      psAge: booking?.psAge ?? "",
      errorCheck: booking?.errorCheck ?? "",
      notes: booking?.notes ?? "",
      action: booking?._id,
    })) ?? [];

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [status, setStatus] = useState("pending");
  const [activeTab, setActiveTab] = useState(0); // To keep track of the active tab
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const toast = useToast();

  const createDeleteMutation = useDeleteBooking();

  const handleDelete = () => {
    setActionType("delete");
    setIsModalVisible(true);
  };

  const handleUpdate = () => {
    const filter = bookings?.filter((item) => item?._id === selectedIdEdit);
    setSelectedData(filter);
    setSelectedIdEdit(null);
    onOpen();
  };

  useEffect(() => {
    if (selectedId) {
      handleDelete();
    }
    if (selectedIdEdit) {
      handleUpdate();
    }
  }, [selectedId, selectedIdEdit]);

  const handleConfirm = async () => {
    setIsModalVisible(false);
    createDeleteMutation.mutate(
      { apiUrl: `/api/booking/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);
          toast({
            title: "Booking Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          console.log("Error deleted booking");
          toast({
            title: "Error deleted booking",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setActionType(null);
    setSelectedId(null);
  };

  const btnComponent = (props) => {
    const handleEdit = () => {
      console.log(props?.data?._id);
      setSelectedIdEdit(props?.data?._id);
    };

    const handleDeleteClick = () => {
      setSelectedId(props?.data?._id);
    };

    return (
      <div className="flex gap-5 pt-2">
        <EditBtn onClick={handleEdit} />
        <DeleteBtn onClick={handleDeleteClick} />
      </div>
    );
  };

  const columns = [
    {
      headerName: "_id",
      field: "_id",
      hide: true,
    },
    { headerName: "ID", field: "id" },
    { headerName: "Breeder", field: "breeder" },
    { headerName: "USDA Name", field: "usdaName" },
    { headerName: "Breed", field: "breed" },
    {
      headerName: "Birth Date",
      valueGetter: (params) => getDate(params.data.dateOfBirth),
    },
    { headerName: "USDA ID", field: "usdaId" },
    { headerName: "Gender", field: "gender" },
    { headerName: "Color", field: "color" },
    { headerName: "Type", field: "type" },
    { headerName: "Registration", field: "registration" },
    { headerName: "Dam #", field: "dam" },
    { headerName: "Sire #", field: "coupon" },
    { headerName: "S-Program", field: "sProgram" },
    {
      headerName: "Disposition Date",
      valueGetter: (params) => getDate(params.data.dispositionDate),
    },
    { headerName: "Ps Week", field: "psWeek" },
    { headerName: "Ps Age", field: "psAge" },
    { headerName: "Error Check", field: "errorCheck" },
    { headerName: "Notes", field: "notes" },
    { headerName: "Action", cellRenderer: btnComponent },
  ];

  const defaultColDef = {
    sortable: true,
    editable: true,
    filter: true,
    floatingFilter: true,
  };

  const items = [
    { label: "1st menu item", key: "1" },
    { label: "2nd menu item", key: "2" },
    { label: "3rd menu item", key: "3", danger: true },
    { label: "4th menu item", key: "4", danger: true, disabled: true },
  ];

  const menuProps = {
    items,
    onClick: (e) => console.log("Click", e),
  };

  const getFilteredBooking = () => {
    return transformBooking?.filter();
  };

  if (isLoading) return <GlobalLoader />;
  if (error) return <p>Error loading orders</p>;

  // console.log(transformOrders, "transformOrders");
  // console.log(orders, "orders");

  return (
    <div>
      <div className="my-8 pl-[14px]">
        <div className="flex flex-1 flex-col">
          <button
            onClick={onOpen}
            className="self-start rounded-lg border-2 border-black bg-transparent px-4 py-2 hover:bg-black hover:text-white"
          >
            Add New Records
          </button>

          <GlobalModalV1 onClose={onClose} isOpen={isOpen} width={"800px"}>
            <BookingForms
              onClose={onClose}
              isOpen={isOpen}
              selectedData={selectedData}
            />
          </GlobalModalV1>
        </div>
      </div>
      <div className="my-10">
        <Tabs onChange={(index) => setActiveTab(index)}>
          <TabPanels>
            <TabPanel>
              <div
                className="ag-theme-quartz"
                style={{ height: 700, overflowX: "auto" }}
              >
                <AgGridReact
                  modules={[ClientSideRowModelModule]}
                  rowData={transformBooking}
                  columnDefs={columns}
                  defaultColDef={defaultColDef}
                  rowSelection="multiple"
                  suppressRowClickSelection={true}
                  pagination={true}
                  paginationPageSize={50}
                  paginationPageSizeSelector={[10, 25, 50]}
                  // domLayout="autoHeight"
                />
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
      <GlobalModalV1 isOpen={isOpen} onClose={onClose}>
        <BookingForms
          onClose={onClose}
          isUpdateModal={true}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      </GlobalModalV1>
      <AntModal
        centered
        isOpen={isModalVisible}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title={
          actionType === "approve" ? "Confirm Approval" : "Confirm Deletion"
        }
        content={
          actionType === "approve"
            ? "Are you sure you want to approve this booking?"
            : "Are you sure you want to delete this booking?"
        }
        okText={"Confirm"}
      />
    </div>
  );
};

export default Puppies;
