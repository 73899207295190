import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";
import { ReactSortable } from "react-sortablejs";

import { useToast } from "@chakra-ui/react";

import { uploadFiles, uploadVideo } from "../../../utils/uploadFile";

import { CiSquareRemove } from "react-icons/ci";
import {
  useCreatePups,
  useUpdatePupsMutation,
} from "../../../features/pups/pupsService";
import { useAllBreeds } from "../../../features/Breeds/Breeds";
import moment from "moment";
import dayjs from "dayjs";
import { breederOptions } from "../../../utils/data";
import { useAllParents } from "../../../features/parent/parentService";
import { useAllBreeder } from "../../../features/Breeder/breederService";
import { useAllAkc } from "../../../features/Akc/AkcService";
import { deleteImages, handleBeforeUpload } from "../../../utils";

const { TextArea } = Input;

const PupsForm = ({
  onClose,
  selectedData,
  isUpdateModal,
  setSelectedData,
}) => {
  const { data: breeds } = useAllBreeds("/api/breeds/");
  const { data: parents } = useAllParents("/api/parents");
  const { data: breeders } = useAllBreeder("/api/breeders");
  const { data: Akcs } = useAllAkc("/api/Akcs");

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const createMutation = useCreatePups();
  const UpdateMutation = useUpdatePupsMutation();

  const [form] = Form.useForm();

  const [video, setVideo] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [selection, setSelection] = useState("breed");
  console.log("selectedData==>", selectedData);

  //Update fom functionality
  useEffect(() => {
    if (selectedData && isUpdateModal) {
      const dateAcquired = selectedData[0]?.puppyInformation?.dateAcquired
        ? dayjs(selectedData[0].puppyInformation.dateAcquired)
        : null;

      const dateOfBirth = selectedData[0]?.puppyInformation?.dateOfBirth
        ? dayjs(selectedData[0].puppyInformation.dateOfBirth)
        : null;

      // Convert existing photos to the format expected by Upload component
      const existingPhotos =
        selectedData[0]?.listingInformation?.photos?.map((photo, index) => ({
          uid: `-${index}`,
          name: `photo-${index}`,
          status: "done",
          url: photo,
        })) || [];

      setPhotos(existingPhotos);

      if (selectedData[0]?.puppyInformation?.video) {
        const videoUrl = selectedData[0].puppyInformation.video;
        if (videoUrl) {
          setVideo([
            {
              uid: "-1",
              name: "video",
              status: "done",
              url: videoUrl,
            },
          ]);
        } else {
          console.error("Error loading video URL");
        }
      }

      form.setFieldsValue({
        puppyInformation: {
          breed: selectedData[0]?.puppyInformation?.breed?._id,
          breeder: selectedData[0]?.puppyInformation?.breeder,
          parent: selectedData[0]?.puppyInformation?.parent,
          kennel: selectedData[0]?.puppyInformation?.kennel,
          cageNumber: selectedData[0]?.puppyInformation?.cageNumber,
          color: selectedData[0]?.puppyInformation?.color,
          idNumber: selectedData[0]?.puppyInformation?.idNumber,
          usdaId: selectedData[0]?.puppyInformation?.usdaId,
          localPickup: selectedData[0]?.puppyInformation?.localPickup,
          gender: selectedData[0]?.puppyInformation?.gender,
          registration: selectedData[0]?.puppyInformation?.registration,
          akcAppNumber: selectedData[0]?.puppyInformation?.akcAppNumber,
          dateAcquired: dateAcquired,
          dateOfBirth: dateOfBirth,
          video: selectedData[0]?.puppyInformation?.video,
          weight: selectedData[0]?.puppyInformation?.weight,
          testResults1: selectedData[0]?.puppyInformation?.testResults1 ?? null,
          meds1: selectedData[0]?.puppyInformation?.meds1 ?? null,
          testResults2: selectedData[0]?.puppyInformation?.testResults2 ?? null,
          meds2: selectedData[0]?.puppyInformation?.meds2 ?? null,
          testResults3: selectedData[0]?.puppyInformation?.testResults3 ?? null,
          meds3: selectedData[0]?.puppyInformation?.meds3 ?? null,
          testResults4: selectedData[0]?.puppyInformation?.testResults4 ?? null,
          meds4: selectedData[0]?.puppyInformation?.meds4 ?? null,
          dateSold: selectedData[0]?.puppyInformation?.dateSold
            ? dayjs(selectedData[0]?.puppyInformation?.dateSold)
            : null,
          wholesaleTransferDate: selectedData[0]?.puppyInformation
            ?.wholesaleTransferDate
            ? dayjs(selectedData[0]?.puppyInformation?.wholesaleTransferDate)
            : null,
          purchaser: selectedData[0]?.puppyInformation?.purchaser,
          cost: selectedData[0]?.puppyInformation?.cost,
        },
        listingInformation: {
          active: selectedData[0]?.listingInformation?.active,
          featured: selectedData[0]?.listingInformation?.featured,
          perfect10: selectedData[0]?.listingInformation?.perfect10,
          doNotList: selectedData[0]?.listingInformation?.doNotList,
          nurturingNotice: selectedData[0]?.listingInformation?.nurturingNotice,
          heading: selectedData[0]?.listingInformation?.heading,
          name: selectedData[0]?.listingInformation?.name,
          title: selectedData[0]?.listingInformation?.title,
          description: selectedData[0]?.listingInformation?.description,
          whatComesWithDog:
            selectedData[0]?.listingInformation?.whatComesWithDog,
          price: selectedData[0]?.listingInformation?.price,
          adultWeight: selectedData[0]?.listingInformation?.adultWeight,
          quantity: selectedData[0]?.listingInformation?.quantity,
          makeOffer: selectedData[0]?.listingInformation?.makeOffer,
          offerPrice: selectedData[0]?.listingInformation?.offerPrice,
          photos: existingPhotos,
          registrationPaperwork:
            selectedData[0]?.listingInformation?.registrationPaperwork,
          presalePups: selectedData[0]?.listingInformation?.presalePups,
        },
      });
    }
  }, [selectedData, isUpdateModal]);

  //From Submit Function
  const onFinish = async (values) => {
    // Add validation check for photos
    if (!photos.length) {
      toast({
        title: "At least one photo is required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    console.log(values, "values");

    const { puppyInformation, listingInformation } = values;

    try {
      // Handle photo uploads first
      let photoUrls = [];
      if (photos?.length) {
        // Filter out already uploaded photos (those with urls) from new ones that need uploading
        const newPhotos = photos.filter((photo) => !photo.url);
        const existingPhotos = photos
          .filter((photo) => photo.url)
          .map((photo) => photo.url);

        if (newPhotos.length) {
          // Upload new photos and get their URLs
          const uploadedPhotoUrls = await uploadFiles(
            newPhotos.map((photo) => photo.originFileObj),
          );
          photoUrls = [...existingPhotos, ...uploadedPhotoUrls];
        } else {
          photoUrls = existingPhotos;
        }
      }

      // Handle video upload
      // let videoUrl = null;
      // if (video?.length) {
      //   if (!video[0].url) {
      //     const uploadedVideoUrls = await uploadFiles([video[0].originFileObj]);
      //     videoUrl = uploadedVideoUrls[0];
      //     console.log("Video leUrls Pup listing form=====>", videoUrl);
      //   } else {
      //     videoUrl = video[0].url;
      //   }
      // }

      let videoUrl = null;

      if (video && video.originFileObj) {
        try {
          console.log("Uploading video:", video.originFileObj);
          const uploadedVideoUrl = await uploadVideo(video.originFileObj);
          if (uploadedVideoUrl) {
            videoUrl = uploadedVideoUrl;
            console.log("Uploaded video URL:", videoUrl);
          } else {
            console.error("Failed to retrieve video URL from uploadVideo.");
          }
        } catch (error) {
          console.error("Error during video upload:", error);
        }
      } else {
        console.error("Video object is not set correctly:", video);
      }

      const data = {
        puppyInformation: {
          ...puppyInformation,
          video: videoUrl,
        },
        listingInformation: {
          ...listingInformation,
          photos: photoUrls,
        },
      };

      if (selectedData && selectedData.length > 0 && isUpdateModal) {
        UpdateMutation.mutate(
          {
            apiUrl: `/api/puppy/${selectedData[0]?._id}`,
            data,
          },
          {
            onSuccess: () => {
              setVideo([]);
              setPhotos([]);
              form.resetFields();
              toast({
                title: "Puppy Updated successfully!",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              onClose();
              setSelectedData([]);
            },
            onError: (error) => {
              toast({
                title: error.response?.data?.details?.includes("duplicate")
                  ? "Puppy Already Exist"
                  : error.response?.data?.details ||
                    error.message ||
                    "Error Updating Puppy",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            },
          },
        );
      } else {
        createMutation.mutate(
          {
            apiUrl: "/api/puppy",
            data,
          },
          {
            onSuccess: () => {
              setVideo([]);
              setPhotos([]);
              form.resetFields();
              toast({
                title: "Puppy created successfully!",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              onClose();
            },
            onError: (error) => {
              toast({
                title: error.response?.data?.details?.includes("duplicate")
                  ? "Puppy Already Exist"
                  : error.response?.data?.details ||
                    error.message ||
                    "Error creating Puppy",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            },
          },
        );
      }
    } catch (error) {
      toast({
        title: "Error uploading files",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const breedOptions = breeds?.map((breed) => ({
    value: breed._id,
    label: breed?.breedInformation?.breedName,
  }));
  const breederOptions = breeders?.map((breeder) => ({
    value: breeder._id,
    label: breeder?.firstName,
  }));

  const parentOptions = parents?.map((parent) => ({
    value: parent._id,
    label: parent?.parentInformation?.parentName,
  }));

  const akcOptions = Akcs?.map((akc) => ({
    value: akc?._id,
    label: akc?.registrationNumber,
  }));

  const options = [
    "Presale Pups",
    "FFB",
    "PR",
    "SDC",
    "WDC",
    "SEA",
    "LSP",
    "PGP",
    "PP CHICAGO",
    "PP MIAMI",
    "PP OHIO",
  ];

  const handleRemove = (file) => {
    setPhotos((prev) => prev.filter((f) => f.uid !== file.uid));
  };

  return (
    <div className="auto mx-0 mt-10 min-h-screen overflow-y-auto">
      <Form
        form={form}
        // wrapperCol={{ span:  }}
        layout="horizontal"
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">
          Puppy Information
        </h1>
        <div className="m-auto grid grid-cols-2 gap-20 pt-10">
          <div>
            <div>
              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Breed Information
              </div>

              <Form.Item
                name={["puppyInformation", "breed"]}
                label="Breed"
                rules={[{ required: selection === "breed" }]}
              >
                <Select
                  placeholder="Select a breed"
                  style={{ width: "100%" }}
                  allowClear
                  options={breedOptions}
                  disabled={isLoading || selection !== "breed"}
                />
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "parent"]}
                label="Parent"
                rules={[{ required: selection === "parent" }]}
                required
              >
                <Select
                  placeholder="Select a Parent"
                  style={{ width: "100%" }}
                  allowClear
                  options={parentOptions}
                />
              </Form.Item>
              <Form.Item
                // name={["puppyInformation", "parent"]}
                label="2nd Parent"
              >
                {/* <Select
                  placeholder="Select a 2nd Parent"
                  style={{ width: "100%" }}
                  allowClear
                  // options={parentOptions}
                /> */}
                <Input placeholder="2nd parent" disabled={isLoading} />
              </Form.Item>
              <Form.Item
                name={["puppyInformation", "breeder"]}
                label="Breeder"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Select a breeder"
                  style={{ width: "100%" }}
                  allowClear
                  // options={breedOptions}
                  options={breederOptions}
                  disabled={isLoading}
                />
              </Form.Item>

              {/* <Form.Item name={["puppyInformation", "kennel"]} label="Kennel">
                <Select
                  placeholder="kennel"
                  style={{ width: "100%" }}
                  allowClear
                  options={[
                    { label: "1", value: "1" },
                    { label: "2", value: "2" },
                    { label: "3", value: "3" },
                    { label: "4", value: "4" },
                    { label: "PS", value: "PS" },
                    { label: "NK", value: "NK" },
                  ]}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "cageNumber"]}
                label="Cage Number"
              >
                <Select
                  placeholder="Cage Number"
                  style={{ width: "100%" }}
                  allowClear
                  options={[
                    { label: "10A", value: "10A" },
                    { label: "10B", value: "10B" },
                    { label: "10C", value: "10C" },
                  ]}
                  disabled={isLoading}
                />
              </Form.Item> */}

              <Form.Item name={["puppyInformation", "color"]} label="Color">
                <Input placeholder="Color" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "idNumber"]}
                label="ID Number"
              >
                <Input placeholder="ID Number" disabled={isLoading} />
              </Form.Item>

              <Form.Item name={["puppyInformation", "usdaId"]} label="USDA ID">
                <Input placeholder="USDA ID" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "localPickup"]}
                valuePropName="checked"
                label="Local Pickup"
              >
                <Checkbox disabled={isLoading}></Checkbox>
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "gender"]}
                label="Gender"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Male or Female"
                  style={{ width: "100%" }}
                  allowClear
                  options={[
                    { label: "Male", value: "Male" },
                    { label: "Female", value: "Female" },
                  ]}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "registration"]}
                label="Registration"
              >
                <Select
                  placeholder="ACA, AKC, CKC, ICA, n/a"
                  style={{ width: "100%" }}
                  allowClear
                  options={[
                    { label: "ACA", value: "ACA" },
                    { label: "AKC", value: "AKC" },
                    { label: "CKC", value: "CKC" },
                    { label: "ICA", value: "ICA" },
                    { label: "n/a", value: "n/a" },
                  ]}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "akcAppNumber"]}
                label="AKC Application Number"
              >
                <Select
                  placeholder="AKC Application Number"
                  style={{ width: "100%" }}
                  allowClear
                  options={akcOptions}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "dateAcquired"]}
                label="Date Acquired"
              >
                <DatePicker disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "dateOfBirth"]}
                label="Date of Birth"
              >
                <DatePicker disabled={isLoading} />
              </Form.Item>

              {/* <Form.Item name={["puppyInformation", "video"]} label="Video">
                <Upload
                  listType="picture-card"
                  fileList={video}
                  beforeUpload={(file) => {
                    setVideo([file]);
                    return false; // Prevent automatic upload
                  }}
                  onRemove={(file) => {
                    setVideo([]);
                  }}
                  disabled={isLoading}
                >
                  {video.length === 0 && (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </Form.Item> */}

              <Form.Item name={["puppyInformation", "video"]} label="Video">
                <Upload
                  listType="picture-card"
                  fileList={video ? [video] : []}
                  beforeUpload={(file) => {
                    console.log("Selected file:", file);
                    setVideo({
                      ...file,
                      originFileObj: file,
                      status: "done",
                    });
                    return false;
                  }}
                  onRemove={() => setVideo(null)}
                  disabled={isLoading}
                >
                  {!video && (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>

              <Form.Item name={["puppyInformation", "weight"]} label="Weight">
                <Input placeholder="Weight in lbs" disabled={isLoading} />
              </Form.Item>

              {/* <Form.Item
                name={["puppyInformation", "testResults"]}
                label="Test Results"
              >
                <TextArea
                  placeholder="Test Results"
                  rows={4}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item name={["puppyInformation", "meds"]} label="Meds">
                <TextArea
                  placeholder="Medications"
                  rows={4}
                  disabled={isLoading}
                />
              </Form.Item> */}

              <Form.Item
                name={["puppyInformation", "testResults1"]}
                label="Test Results 1"
              >
                <Select
                  placeholder="Test Results 1"
                  style={{ width: "100%" }}
                  allowClear
                  options={[{ label: "Abnormality", value: "Abnormality" }]}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item name={["puppyInformation", "meds1"]} label="meds1">
                <Select
                  placeholder="meds1"
                  style={{ width: "100%" }}
                  allowClear
                  options={[{ label: "Abnormality", value: "Abnormality" }]}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item
                name={["puppyInformation", "testResults2"]}
                label="Test Results 2"
              >
                <Select
                  placeholder="Test Results 2"
                  style={{ width: "100%" }}
                  allowClear
                  options={[{ label: "Abnormality", value: "Abnormality" }]}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item name={["puppyInformation", "meds2"]} label="meds2">
                <Select
                  placeholder="meds2"
                  style={{ width: "100%" }}
                  allowClear
                  options={[{ label: "Abnormality", value: "Abnormality" }]}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item
                name={["puppyInformation", "testResults3"]}
                label="Test Results 3"
              >
                <Select
                  placeholder="Test Results 3"
                  style={{ width: "100%" }}
                  allowClear
                  options={[{ label: "Abnormality", value: "Abnormality" }]}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item name={["puppyInformation", "meds3"]} label="meds3">
                <Select
                  placeholder="meds3"
                  style={{ width: "100%" }}
                  allowClear
                  options={[{ label: "Abnormality", value: "Abnormality" }]}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "testResults4"]}
                label="Test Results 4"
              >
                <Select
                  placeholder="Test Results 4"
                  style={{ width: "100%" }}
                  allowClear
                  options={[{ label: "Abnormality", value: "Abnormality" }]}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item name={["puppyInformation", "meds4"]} label="meds4">
                <Select
                  placeholder="meds4"
                  style={{ width: "100%" }}
                  allowClear
                  options={[{ label: "Abnormality", value: "Abnormality" }]}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item
                name={["puppyInformation", "dateSold"]}
                label="Date Sold"
              >
                <DatePicker disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "wholesaleTransferDate"]}
                label="Wholesale Transfer Date"
              >
                <DatePicker disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "purchaser"]}
                label="Purchaser"
              >
                <Select
                  placeholder="Purchaser"
                  style={{ width: "100%" }}
                  allowClear
                  options={[
                    { label: "N/A", value: "N/A" },
                    { label: "Capital City Pups", value: "Capital City Pups" },
                    { label: "Puppy Love Pr", value: "Puppy Love Pr" },
                  ]}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item name={["puppyInformation", "cost"]} label="Cost">
                <Input placeholder="Cost" disabled={isLoading} />
              </Form.Item>
            </div>
          </div>

          <div>
            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Listing Information
            </div>
            {/* Breed Details */}
            <Form.Item
              name={["listingInformation", "active"]}
              valuePropName="checked"
              label="Active"
            >
              <Checkbox disabled={isLoading} defaultChecked></Checkbox>
            </Form.Item>

            <Form.Item
              name={["listingInformation", "featured"]}
              valuePropName="checked"
              label="Featured"
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>

            <Form.Item
              name={["listingInformation", "perfect10"]}
              valuePropName="checked"
              label="Perfect 10"
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>

            <Form.Item
              name={["listingInformation", "doNotList"]}
              valuePropName="checked"
              label="Do Not List"
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>

            <Form.Item
              name={["listingInformation", "nurturingNotice"]}
              label="Nurturing Notice"
            >
              <TextArea
                placeholder="Nurturing Notice"
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["listingInformation", "heading"]}
              label="Heading"
              rules={[{ required: true }]}
            >
              <Input placeholder="Listing Heading" disabled={isLoading} />
            </Form.Item>

            <Form.Item name={["listingInformation", "name"]} label="Name">
              <Input placeholder="Puppy Name" disabled={isLoading} />
            </Form.Item>

            <Form.Item name={["listingInformation", "title"]} label="Title">
              <Input placeholder="Listing Title" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["listingInformation", "description"]}
              label="Description"
            >
              <TextArea
                placeholder="Listing Description"
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["listingInformation", "whatComesWithDog"]}
              label="What Comes With Dog"
            >
              <TextArea
                placeholder="Items included with dog"
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["listingInformation", "price"]}
              label="Price"
              rules={[{ required: true }]}
            >
              <Input placeholder="Price" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["listingInformation", "adultWeight"]}
              label="Adult Weight"
            >
              <Input placeholder="Adult Weight in lbs" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["listingInformation", "quantity"]}
              label="Quantity"
              rules={[{ required: true }]}
            >
              <Input placeholder="Quantity" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["listingInformation", "makeOffer"]}
              valuePropName="checked"
              label="Make Offer"
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>

            <Form.Item
              name={["listingInformation", "offerPrice"]}
              label="Offer Price"
            >
              <Input placeholder="Offer Price" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["listingInformation", "photos"]}
              label="Photos"
              rules={[
                {
                  validator: (_, value) => {
                    if (!photos.length) {
                      return Promise.reject("At least one photo is required");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              required
            >
              <ReactSortable
                animation={200}
                delayOnTouchStart={true}
                delay={2}
                list={photos}
                setList={setPhotos}
                className="flex cursor-move gap-4 pb-4"
              >
                {photos.map((file) => (
                  <Upload
                    key={file.uid}
                    listType="picture-card"
                    fileList={[file]}
                    showUploadList={{ showRemoveIcon: true }}
                    disabled={isLoading}
                    onRemove={() => deleteImages(file, setPhotos)}
                  />
                ))}
              </ReactSortable>

              {/* Upload button to add more images */}
              {photos.length < 5 && (
                <Upload
                  listType="picture-card"
                  beforeUpload={(file) => handleBeforeUpload(file, setPhotos)}
                  showUploadList={false}
                  multiple
                  disabled={isLoading}
                >
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </Upload>
              )}

              <span className="text-red-600">
                *Image dimensions should be 1200x800
              </span>
            </Form.Item>

            <Form.Item
              name={["listingInformation", "presalePups"]}
              label="PreSale Pups"
            >
              <Checkbox.Group options={options} defaultValue={["Apple"]} />
            </Form.Item>
          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button
              className="!bg-[#001219] !text-white"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default PupsForm;
