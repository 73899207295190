import React, { useMemo, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import toast from "react-hot-toast";

import {
  useAllReviews,
  useDeleteReviewsMutation,
  useUpdateReviewMutation,
} from "../../../features/reviews/reviewsService.js";
import AntModal from "../../../components/AntModal.jsx";
import DOMPurify from "dompurify";
import { useAllEmail } from "../../../features/EmailAndSms/emailAndSmsService.js";
import {
  useAllEmailList,
  useCreateEmailCampaign,
} from "../../../features/EmailCampaign/emailCampaignService.js";
import { Modal, Select } from "antd";
import DebounceSelect from "../../../components/DebounceSelect.jsx";

const TableEmailAndSms = () => {
  const {
    data: templateData,
    isLoading,
    error,
  } = useAllEmail("/api/templates");

  const { data: emailList } = useAllEmailList("/api/emails");
  console.log("All customers emails===>", emailList);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filters, setFilters] = useState({
    available: "",
    details: "",
    views: "",
    checkboxes: "",
    desc: "",
    wholesale: "",
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [currentReviewId, setCurrentReviewId] = useState(null);
  // const [updateOrdelete, setUpdateOrDelete] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [data, setData] = useState(null);
  const [currentReviewApproval, setCurrentReviewApproval] = useState(null);
  const [value, setValue] = useState([]);

  const UpdateMutation = useUpdateReviewMutation();
  const DeleteMutation = useDeleteReviewsMutation();
  const createEmailMutation = useCreateEmailCampaign();

  console.log("Email templates===>", templateData);

  //making a approved review function
  const approvedReview = async (e, id, type, approvalStatus) => {
    e.stopPropagation();
    setCurrentReviewId(id);
    setIsModalVisible(true);
    // setUpdateOrDelete(true);
    setActionType(type);
    setCurrentReviewApproval(approvalStatus);
  };

  // const createDeleteMutation = useDeleteReviewsMutation();

  const handleDelete = (e, id) => {
    e.stopPropagation();
    setCurrentReviewId(id);
    setIsModalVisible(true);
    // setUpdateOrDelete(false);
  };

  //Confirmation popup modal handler
  const handleSendClick = (e, templateData, id, type) => {
    e.stopPropagation();
    setCurrentReviewId(id);
    setIsModalVisible(true);
    setActionType(type);

    const listOfEmail = emailList.map((emails) => emails);
    const campaignData = {
      emailList: listOfEmail ?? null,
      emailTemplate: templateData ?? null,
    };

    setData(campaignData);
  };

  const emailOptions =
    emailList?.map((emailObj) => ({
      label: emailObj.email,
      value: emailObj.email,
      key: emailObj._id,
    })) || [];

  // Handle changes when user selects emails
  const handleEmailChange = (value) => {
    const campaignData = {
      emailList: value ?? null,
      emailTemplate: templateData ?? null,
    };
    setData(campaignData);
    console.log("Selected Emails Array: ", data); 
  };

  //Confirmation handler to send email for all users
  const handleConfirm = async () => {
    setModal2Open(false);
    if (data) {
      try {
        await createEmailMutation.mutateAsync({
          apiUrl: "/api/email-campaign",
          data,
        });

        toast({
          title: "Emails sent successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: error.response?.data?.details?.includes("duplicate")
            ? "Error sending email"
            : error.response?.data?.details ||
              error.message ||
              "Error sending email",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      console.error("Email list is empty or not loaded yet.");
    }
  };

  const handleSelectedClick = () => {
    setModal2Open(true);
  };

  // async function fetchUserList(username) {
  //   console.log("fetching user", username);

  //   // Check if emailList is available
  //   if (!emailList) {
  //     return [];
  //   }

  //   const filteredEmails = emailList
  //     .filter((emailObj) =>
  //       emailObj.email.toLowerCase().includes(username.toLowerCase()),
  //     )
  //     .map((emailObj) => ({
  //       label: emailObj.email,
  //       value: emailObj._id,
  //     }));
  //   const selectedUsersEmails = filteredEmails.map((email) => email.label);
  //   setData([...selectedUsersEmails]);
  //   console.log("selected Users Emails====>", selectedUsersEmails);
  //   console.log("Filtered Emails===>", filteredEmails);
  //   console.log("Usernames=====>", username);
  //   console.log("Data==========>", data);
  //   return filteredEmails;
  // }

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentReviewId(null);
    setActionType(null);
  };

  // const handleFilterChange = (e) => {
  //   setFilters({
  //     ...filters,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // if (isLoading) return <GlobalLoader />;
  if (error) return <p>Error loading orders</p>;

  //   const filteredData = gallery_tableData.filter(
  //     (item) =>
  //       item.available.includes(filters.available) &&
  //       item.details.toLowerCase().includes(filters.details.toLowerCase())
  //       &&
  //       item.views.toLowerCase().includes(filters.views.toLowerCase()) &&
  //       item.checkboxes
  //         .toLowerCase()
  //         .includes(filters.checkboxes.toLowerCase()) &&
  //       item.desc.toLowerCase().includes(filters.desc.toLowerCase()) &&
  //       item.wholesale.toLowerCase().includes(filters.wholesale.toLowerCase()),
  //   );

  return (
    <div className="my-10">
      <div className="my-10">
        <div className="rounded-xl border border-gray-300">
          <div className="grid grid-cols-12 gap-4 bg-white p-4">
            <div className="col-span-2 grid grid-cols-2 gap-4">Variable</div>
            <div className="col-span-2 grid grid-cols-2 gap-4">
              Setting Type
            </div>

            <div className="col-span-6 font-bold">Templates</div>
            <div className="col-span-2 grid grid-cols-2 gap-4">Actions</div>
          </div>
          {templateData &&
            templateData.map((item, index) => (
              <div
                className={`grid grid-cols-12 gap-4 border-t border-gray-200 p-6 ${
                  index % 2 === 0 ? "bg-white/70" : "bg-transparent"
                }`}
                key={item.variable}
              >
                <div className="col-span-2 gap-4 border-r">
                  <div>
                    <p>{item.variable && <span>{item.variable} </span>}</p>
                  </div>
                </div>
                <div className="col-span-2 gap-4 border-r">
                  <div>
                    <p>
                      {item.settingType && <span>{item.settingType} </span>}
                    </p>
                  </div>
                </div>
                <div className="col-span-6 gap-4 border-r">
                  <div
                    className="flex w-full max-w-5xl flex-col"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(item?.template),
                    }}
                  />
                </div>
                <div className="col-span-2 gap-4 border-r">
                  <div className="mt-2 flex flex-col justify-around gap-4 p-4">
                    <button
                      onClick={(e) =>
                        handleSendClick(e, item?.template, item?._id, "approve")
                      }
                      className="rounded bg-blue-500 px-2 py-1 text-sm text-white"
                    >
                      Send email to all
                    </button>

                    {/* <button
                      onClick={handleSelectedClick}
                      className="rounded bg-red-500 px-2 py-1 text-sm text-white"
                    >
                      Send selected user only
                    </button>
                    <button className="rounded bg-green-500 px-2 py-1 text-sm text-white">
                      Edit template
                    </button> */}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <AntModal
        centered
        isOpen={isModalVisible}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title={
          actionType === "approve" ? "Confirm Approval" : "Confirm Deletion"
        }
        content={
          actionType === "approve"
            ? `This email template sent to all ${emailList.length} customers in your records. Are you sure want to send?`
            : "Are you sure you want to delete this review?"
        }
        okText={"Confirm"}
      />

      <Modal
        title="Send email template to selected users"
        centered
        open={modal2Open}
        onOk={handleConfirm}
        onCancel={() => setModal2Open(false)}
      >
        {/* <DebounceSelect
          mode="multiple"
          value={value}
          placeholder="Select users"
          fetchOptions={fetchUserList}
          // fetchOptions={(username) => (username ? fetchUserList(username) : [])}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          style={{
            width: "100%",
          }}
        /> */}
        <Select
          mode="multiple"
          allowClear
          showSearch
          placeholder="Search and select emails"
          onChange={handleEmailChange}
          style={{ width: "100%" }}
          options={emailOptions} // Directly use options array from the mapped emailList
          optionFilterProp="label" // Filters based on label (email)
          filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
        />
      </Modal>
    </div>
  );
};

export default TableEmailAndSms;
