import { useToast } from "@chakra-ui/react";
import { Button, Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import {
  useCreatePage,
  useUpdatePage,
} from "../../../features/Page/pageServices";
import TextEditor from "../../../components/TextEditor";
import { useAllEmail } from "../../../features/EmailAndSms/emailAndSmsService";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

const EmailTemplateForm = ({ pageHandler, selectedData, setSelectedData }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const toast = useToast();
  const createMutation = useCreatePage();
  const UpdateMutation = useUpdatePage();

  const { data: email, isLoading, error } = useAllEmail("/api/templates");
  console.log("Email Template Data all emails data", email);

  useEffect(() => {
    if (selectedData) {
      form.setFieldsValue({
        title: selectedData[0]?.title ?? null,
        template: selectedData[0]?.template ?? null,
        smsText: selectedData[0]?.smsText ?? null,
        variable: selectedData[0]?.variable ?? null,
        settingType: selectedData[0]?.settingType ?? null,
      });
    }
  }, [selectedData]);

  console.log("selectedData==>", selectedData);

  // const emailFormSchema = yup
  //   .object({
  //     title: yup.string().required("Title is required"),
  //     template: yup.string().nullable().notRequired(),
  //     // .required("For Creating email template, write and modify the template"),
  //     smsText: yup.string().required().nullable().notRequired(),
  //     variable: yup.string().required("Variable name is required"),
  //     settingType: yup.string().required("Setting type is required"),
  //   })
  //   .required();
  const emailFormSchema = yup.object({
    title: yup.string().required("Title is required"),
    template: yup.string().nullable().notRequired(),
    smsText: yup.string().nullable().notRequired(),
    variable: yup.string().required("Variable name is required"),
    settingType: yup.string().required("Setting type is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors: emailFormError },
  } = useForm({ resolver: yupResolver(emailFormSchema) });

  const onFinish = async (values) => {
    setLoading(true);
    console.log(values, "values");

    const data = {
      title: values.title ?? null,
      template: values.template ?? null,
      smsText: values.smsText ?? null,
      variable: values.variable ?? null,
      settingType: values.settingType ?? null,
    };

    // console.log("Email And Sms setting Data==>", data);

    try {
      if (selectedData && selectedData[0]?._id) {
        await UpdateMutation.mutateAsync({
          apiUrl: `/api/template/${selectedData[0]?._id}`,
          data,
        });

        toast({
          title: "Successfully Created email template",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setSelectedData(null);
        pageHandler();
      } else {
        await createMutation.mutateAsync({
          apiUrl: "/api/template",
          data,
        });

        toast({
          title: "Successfully Created email template",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      // Reset form and clear states if needed
      form.resetFields();
      setSelectedData(null);
      pageHandler();
    } catch (error) {
      toast({
        title: error.response?.data?.details?.includes("duplicate")
          ? "Booking Breed Already Exists"
          : error.response?.data?.details ||
            error.message ||
            "Error creating Booking Breed",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      className="mb-10 grid w-full grid-cols-1 gap-5 md:grid-cols-3"
    >
      <div className="md:col-span-2">
        <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
          Content
        </div>
        <Form.Item name="title" label="Title" required={true}>
          <TextArea
            rows={3}
            placeholder="Title"
            // {...register("title")}
          />
          {/* {emailFormError?.title && (
            <p className="py-2 text-red-600">
              {emailFormError?.title?.message}
            </p>
          )} */}
        </Form.Item>
        <Form.Item
          name="template"
          label="Template"
          valuePropName="template"
          trigger="onChange"
        >
          <TextEditor
            placeholder="Write your content here..."
            // {...register("template")}
          />
          {/* {emailFormError?.template && (
            <p className="py-2 text-red-600">
              {emailFormError?.template?.message}
            </p>
          )} */}
        </Form.Item>
        <Form.Item name="smsText" label="Sms Text">
          <TextArea
            rows={6}
            placeholder="Sms Text"
            // {...register("smsText")}
          />
          {/* {emailFormError?.smsText && (
            <p className="py-2 text-red-600">
              {emailFormError?.smsText?.message}
            </p>
          )} */}
        </Form.Item>
      </div>
      <div className="flex flex-col gap-6 md:col-span-1">
        <div className="w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
          Information
        </div>
        <Form.Item name="variable" label="Variable" required={true}>
          <TextArea
            rows={2}
            placeholder="Variable"
            // {...register("variable")}
          />
          {/* {emailFormError?.variable && (
            <p className="py-2 text-red-600">
              {emailFormError?.variable?.message}
            </p>
          )} */}
        </Form.Item>
        <Form.Item name="settingType" label="Setting Type" required={true}>
          <TextArea
            rows={2}
            placeholder="Setting Type"
            // {...register("settingType")}
          />
          {/* {emailFormError?.settingType && (
            <p className="py-2 text-red-600">
              {emailFormError?.settingType?.message}
            </p>
          )} */}
        </Form.Item>
      </div>

      <div className="flex items-center justify-center md:col-span-3">
        <div className="flex gap-4">
          <Button
            className="!bg-[#001219] !text-white"
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
          <Button
            onClick={pageHandler}
            disabled={loading}
            iconPosition="end"
            size="lg"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default EmailTemplateForm;
