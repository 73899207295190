import React from "react";

const PetInsurance = () => {
  return (
    <div className="poppins-regular relative w-full">
      <div
        className="absolute left-0 top-0 h-[650px] w-full bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: "url('/images/top-banner-contact.png')" }}
      ></div>
      <div className="relative z-10 flex w-full flex-col items-center justify-center px-4 pt-56 md:px-32">
        <h6 className="text-3xl md:text-5xl">Call Or Text Anytime</h6>
        <h3 className="my-6 text-2xl font-bold">
          Simply Cavapoos / 10 YEAR GENETIC WARRANTY
        </h3>
        <div className="my-28 flex w-full max-w-5xl flex-col">
          <div className="border-b-4 border-gray-200 pb-8 text-center leading-8">
            <h1 className="my-6 text-3xl font-semibold text-primaryBlack-theme/50">
              Unmatched Pet Insurance, Advice and Love
            </h1>
            <h1 className="my-6 text-3xl font-semibold text-blue-500">
              10% LIFETIME DISCOUNT**
            </h1>
            <h3 className="my-6 text-2xl font-bold">
              (Simply Cavapoos parents get 10% off every month for life)
            </h3>
          </div>
          <div className="pt-8 text-center">
            <h1 className="my-6 text-3xl font-semibold text-primaryBlack-theme/50">
              Accident & Illness coverage at the VET of YOUR CHOICE!
            </h1>
            <h3 className="my-6 text-2xl font-semibold">
              Fetch pays back up to 90% when your pet gets sick or hurt Get the
              best coverage for accidents & illnesses including:
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PetInsurance;
